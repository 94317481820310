import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Store } from '@ngrx/store';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { easing } from 'ts-easing';

import { LaunchDarklyService } from 'app/core/services/launch-darkly/launch-darkly.service';
import { LaunchDarklyFlagValues, PixiInspector } from 'app/core/services/launch-darkly/launch-darkly.types';
import { actions, coreSelectors } from 'app/core/state';
import { notNil } from 'app/utils/stream-util';

export const CUSTOM_FEATURE_FLAGS_KEY = 'custom_feature_flags';

@Component({
  selector: 'feature-flag-debugger',
  templateUrl: './feature-flag-debugger.component.html',
  styleUrls: ['./feature-flag-debugger.component.scss'],
  standalone: false,
})
export class FeatureFlagDebuggerComponent implements OnInit, OnDestroy {
  public flags: Partial<LaunchDarklyFlagValues> = {};

  private destroyed$ = new ReplaySubject<boolean>(1);
  protected pixiInspector = PixiInspector;
  protected easingTypes: string[] = Object.keys(easing);

  public constructor(
    private store: Store,
    private launchDarklyService: LaunchDarklyService,
  ) {}

  public ngOnInit(): void {
    this.store
      .select(coreSelectors.getFeatureFlags)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((flags) => (this.flags = flags ?? {}));
  }

  public isBoolean(flag: LaunchDarklyFlagValues): boolean {
    return typeof flag === 'boolean';
  }

  public isString(flag: LaunchDarklyFlagValues): boolean {
    return typeof flag === 'string';
  }

  protected isObject(flag: LaunchDarklyFlagValues): boolean {
    return typeof flag === 'object';
  }

  protected isNumber(flag: LaunchDarklyFlagValues): boolean {
    return typeof flag === 'number';
  }

  public getString(flag: LaunchDarklyFlagValues): string {
    if (typeof flag === 'string') {
      return flag;
    }
    return JSON.stringify(flag);
  }

  public onToggleChange(flag: keyof LaunchDarklyFlagValues, event: MatSlideToggleChange): void {
    const value = event.checked;
    const flags = { [flag]: value };
    this.store.dispatch(actions.FLAG_CHANGE({ flags }));
    this.saveCustomFlags(flags);
  }

  protected onSelectChange(flag: keyof LaunchDarklyFlagValues, event: MatSelectChange) {
    const value = event.value;
    const flags: Partial<LaunchDarklyFlagValues> = {
      [flag]: typeof this.flags[flag] === 'string' ? value : JSON.parse(value),
    };
    this.store.dispatch(actions.FLAG_CHANGE({ flags }));
    this.saveCustomFlags(flags);
  }

  public onInputBlur(flag: keyof LaunchDarklyFlagValues, event: FocusEvent): void {
    const value = (event.target as HTMLInputElement).value;
    const flags: Partial<LaunchDarklyFlagValues> = {
      [flag]: typeof this.flags[flag] === 'string' ? value : JSON.parse(value),
    };
    this.store.dispatch(actions.FLAG_CHANGE({ flags }));
    this.saveCustomFlags(flags);
  }

  public resetLocalStorage(): void {
    localStorage.removeItem(CUSTOM_FEATURE_FLAGS_KEY);
    this.store.dispatch(actions.FLAG_CHANGE({ flags: this.launchDarklyService.flags }));
  }

  public showResetButton(): boolean {
    const customFlags = localStorage.getItem(CUSTOM_FEATURE_FLAGS_KEY);
    return notNil(customFlags);
  }

  private saveCustomFlags(flags: Partial<LaunchDarklyFlagValues>): void {
    const customFlags = JSON.parse(localStorage.getItem(CUSTOM_FEATURE_FLAGS_KEY) ?? '{}');
    const newCustomFlags = {
      ...customFlags,
      ...flags,
    };
    localStorage.setItem(CUSTOM_FEATURE_FLAGS_KEY, JSON.stringify(newCustomFlags));
  }

  public ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
