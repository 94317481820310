"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.easing = {
  // No easing, no acceleration
  linear: function (t) {
    return t;
  },
  // Accelerates fast, then slows quickly towards end.
  quadratic: function (t) {
    return t * (-(t * t) * t + 4 * t * t - 6 * t + 4);
  },
  // Overshoots over 1 and then returns to 1 towards end.
  cubic: function (t) {
    return t * (4 * t * t - 9 * t + 6);
  },
  // Overshoots over 1 multiple times - wiggles around 1.
  elastic: function (t) {
    return t * (33 * t * t * t * t - 106 * t * t * t + 126 * t * t - 67 * t + 15);
  },
  // Accelerating from zero velocity
  inQuad: function (t) {
    return t * t;
  },
  // Decelerating to zero velocity
  outQuad: function (t) {
    return t * (2 - t);
  },
  // Acceleration until halfway, then deceleration
  inOutQuad: function (t) {
    return t < .5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
  },
  // Accelerating from zero velocity
  inCubic: function (t) {
    return t * t * t;
  },
  // Decelerating to zero velocity
  outCubic: function (t) {
    return --t * t * t + 1;
  },
  // Acceleration until halfway, then deceleration
  inOutCubic: function (t) {
    return t < .5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
  },
  // Accelerating from zero velocity
  inQuart: function (t) {
    return t * t * t * t;
  },
  // Decelerating to zero velocity
  outQuart: function (t) {
    return 1 - --t * t * t * t;
  },
  // Acceleration until halfway, then deceleration
  inOutQuart: function (t) {
    return t < .5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t;
  },
  // Accelerating from zero velocity
  inQuint: function (t) {
    return t * t * t * t * t;
  },
  // Decelerating to zero velocity
  outQuint: function (t) {
    return 1 + --t * t * t * t * t;
  },
  // Acceleration until halfway, then deceleration
  inOutQuint: function (t) {
    return t < .5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t;
  },
  // Accelerating from zero velocity
  inSine: function (t) {
    return -Math.cos(t * (Math.PI / 2)) + 1;
  },
  // Decelerating to zero velocity
  outSine: function (t) {
    return Math.sin(t * (Math.PI / 2));
  },
  // Accelerating until halfway, then decelerating
  inOutSine: function (t) {
    return -(Math.cos(Math.PI * t) - 1) / 2;
  },
  // Exponential accelerating from zero velocity
  inExpo: function (t) {
    return Math.pow(2, 10 * (t - 1));
  },
  // Exponential decelerating to zero velocity
  outExpo: function (t) {
    return -Math.pow(2, -10 * t) + 1;
  },
  // Exponential accelerating until halfway, then decelerating
  inOutExpo: function (t) {
    t /= .5;
    if (t < 1) return Math.pow(2, 10 * (t - 1)) / 2;
    t--;
    return (-Math.pow(2, -10 * t) + 2) / 2;
  },
  // Circular accelerating from zero velocity
  inCirc: function (t) {
    return -Math.sqrt(1 - t * t) + 1;
  },
  // Circular decelerating to zero velocity Moves VERY fast at the beginning and
  // then quickly slows down in the middle. This tween can actually be used
  // in continuous transitions where target value changes all the time,
  // because of the very quick start, it hides the jitter between target value changes.
  outCirc: function (t) {
    return Math.sqrt(1 - (t = t - 1) * t);
  },
  // Circular acceleration until halfway, then deceleration
  inOutCirc: function (t) {
    t /= .5;
    if (t < 1) return -(Math.sqrt(1 - t * t) - 1) / 2;
    t -= 2;
    return (Math.sqrt(1 - t * t) + 1) / 2;
  }
};