<div class="flex flex-column">
  @if (showResetButton()) {
    <button
      type="button"
      mat-button
      (click)="resetLocalStorage()"
      class="m-b-12 bg-color-warning-red text-color-white font-s uppercase"
    >
      Reset Local Storage
    </button>
  }
  @for (flag of flags | keyvalue; track flag) {
    <div class="flex w-full justify-between items-center gap-12">
      <p>{{ flag.key }}</p>
      @if (isBoolean(flag.value)) {
        <mat-slide-toggle
          [checked]="flag.value"
          (change)="onToggleChange(flag.key, $event)"
          hideIcon="true"
          class="custom-toggle"
        ></mat-slide-toggle>
      } @else if (flag.key === 'social_audio_easing_type') {
        <mat-form-field appearance="fill">
          <mat-label>Value</mat-label>
          <mat-select (selectionChange)="onSelectChange(flag.key, $event)" [value]="flag.value">
            @for (easingType of easingTypes; track easingType) {
              <mat-option [value]="easingType">
                {{ easingType }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      } @else if (isString(flag.value) || isNumber(flag.value)) {
        <mat-form-field appearance="fill">
          <mat-label>Value</mat-label>
          <input matInput [value]="getString(flag.value)" (blur)="onInputBlur(flag.key, $event)" />
        </mat-form-field>
      } @else {
        <p>Unsupported type</p>
      }
      @if (flag.key === 'pixi_inspector') {
        <div class="break"></div>
        <code>
          <pre>{{ pixiInspector | json }}</pre>
        </code>
      }
    </div>
  }
</div>
